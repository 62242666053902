<template>
  <div class="row">
    <div
      v-if="course.lessons"
      class="flex md12"
    >
      <va-button
        @click="$router.push({name:'courseSelection'})"
      >
        Back to Course List
      </va-button>
      <va-card
        :title="'Course Editor - Model: ' + course.model.name"
      >
        <div class="d-flex align-items-center justify-content-between">
            <div style="flex-grow: 1; margin-right: 1em;">
              <va-input
                v-model="course.name"
                placeholder="Course Name"
                :messages="['The name of the exam that shows up on the certificate.']"
              />
            </div>
            <div>
              <va-toggle
                v-model="course.active"
                small
                label="Active"
              />
            </div>
          </div>
          <!-- DATA TABLE -->
          <div>
            <div class="flex md12">
                <va-card :title="'Exam Questions'">
                  <va-data-table
                    :handle-data-update="false"
                    :fields="ExamQuestions"
                    :data="FormatExamQuestionData(course, translationData)"
                    :per-page="5"
                    hoverable
                  >
                    <template slot="correctAnswer" slot-scope="props" >
                      <p style="cursor: pointer;" :title="props.rowData.fullCorrectAnswer">{{ props.rowData.correctAnswer }}</p>
                    </template>
                    <template
                      slot="actions"
                      slot-scope="props"
                    >
                        <va-button
                          small
                          @click="EditCurrentAnswerData(props.rowData, course)"
                        >
                        View/Update 
                      </va-button>
                    </template>
                  </va-data-table>
                </va-card>
           </div>
        </div>



          <va-modal
            v-model="editCurrentQuestionModal"
            size="large"
            class="flex xs12"
            :hide-default-actions="true"
            :title="'Editing Exam Question: ' + currentRowData.name"
          > 
            <div class="mx-0" v-if="editCurrentQuestionModal">
              <div style="max-height: 400px; overflow-y: auto;">
                <div>
                     <section>
                      <strong>Question:</strong>
                      <p>{{ this.currentRowData.fullCorrectAnswer }}</p>
                      <strong>Correct Answer:</strong>
                      <div>
                        <va-select
                          style="max-width: 100%;"
                          v-model="storedValues.correctAnswerIndex"
                          :options="filterChoices(this.currentRowData.question.choices)"
                          key-by="id"
                          text-by="text"
                        />
                      </div>
                    </section>
                    <!-- ========= -->
                    <section class="pb-2">
                      <strong>Responses:</strong>
                      <p>Key: <b>{{ this.currentRowData.question.answers[0] }}</b></p> 
                      <ul>
                        <div>
                            <click-to-edit
                              @input="updatedField(this.currentBlueprint, this.currentRowData.question.answers[0],...arguments)"
                              :value="translationData[this.currentRowData.question.answers[0]]"
                            />
                        </div>
                      </ul>
                      <p>Key: <b>{{ this.currentRowData.question.answers[1] }}</b></p> 
                      <ul>
                        <div>
                            <click-to-edit
                              @input="updatedField(this.currentBlueprint, this.currentRowData.question.answers[1],...arguments)"
                              :value="translationData[this.currentRowData.question.answers[1]]"
                            />
                        </div>
                      </ul>
                    </section>
                    <!-- ========= -->
                    <section class="pb-2">
                      <strong>Choices:</strong>
                      <div v-for="choice in this.currentRowData.question.choices">
                        <p>{{ translationData[choice] }}</p> 
                      </div>
                    </section>
                    <!-- ========= -->
                    <section class="pb-2">
                      <strong>Index:</strong>
                      <p>{{ this.currentRowData.question.index }}</p>
                    </section>
                    <!-- ========= -->
                    <section class="pb-2">
                      <strong>Prop(s):</strong>
                      <va-select
                          :label="'Select Prop'"
                          v-model="storedValues.currentPropData"
                          multiple
                          :options="modelProps"
                      />
                    </section> 
                  </div>
              </div>
            </div>

            <div :slot="'actions'">
              <template>
                <va-button
                  @click="storeQuestionChanges()"
                  color="#4040ff"
                >
                  Store 
                </va-button>
                <va-button
                  @click="editCurrentQuestionModal = false"
                  color="#cccccc"
                >
                  Close
                </va-button>
              </template>
            </div>
         </va-modal>
                <!-- <div v-if="field.field == 'answer'">
                  <select v-model="lesson.answer">
                    <option
                      disabled
                      value=""
                    >
                      Please select an answer
                    </option>
                    <option
                      v-for="(choice, choiceIndex) in lesson.choices"
                      :value="choiceIndex"
                      :key="choiceIndex"
                    >
                      {{ translationData[choice] }}
                    </option>
                  </select>
                </div>
                <div v-if="field.type == 'text'">
                  <va-input
                    v-model="lesson[field.field]"
                    :placeholder="field.label"
                  />
                </div>
                <div v-if="field.type == 'prop'">
                  <va-select
                    :label="'Select  Prop'"
                    v-model="lesson.prop"
                    multiple
                    :options="props"
                  />
                </div>
                <div v-if="field.field == 'index'">
                  {{ lesson.index }}
                </div>
                <div v-if="field.type == 'multiple'">
                  <ul>
                    <li
                      v-for="choice in lesson[field.field]"
                      :key="choice"
                    >
                      {{ translationData[choice] }}
                    </li>
                  </ul>
                </div> -->
        <va-button
          @click="saveEdit"
        >
          Save Changes
        </va-button>
      </va-card>
    </div>
  </div>
</template>

<script>

import firebase from 'firebase';
import ClickToEdit from '../ClickToEdit.vue';
import modelProps from '../../data/props';
export default {
  name: 'Course',
  data: function() {
    return {
      currentRowData: {},
      editCurrentQuestionModal: false,
      loading: false,
      loadingNewExam: false,
      modelProps: modelProps,
      course: {},
      simpleSelectModel: [],
      modalData: {name: null},
      showModal: false,
      translationData: {},
      storedValues: {
        correctAnswerIndex: false,
        currentPropData: [],
      },
    };
  },
  components: {
    ClickToEdit
  },
  async created() {
    this.getCourse();
    this.getTranslationData();
  },
  methods: {
    updatedField(lesson, key, value) {
      // create new key
      if (key === 'Response0' || key === 'Response1') {
        const newKey = key + '_' + this.modalData.name.replace(' ', '') + '_' + lesson.question;
        this.translationData[newKey] = value;
        // console.log(this.translationData, this.translationData[key]);
        this.modalData.blueprint[lesson.index].answers[lesson.answers.indexOf(key)] = newKey;
        this.createKey(newKey, value);
        this.$forceUpdate();
      } else {
        this.translationData[key] = value;
      }
    },
    filterChoices(choices){
      if(!choices) return [];
      // // Using map function to create a new array
      let _choices = choices.map((__choice, index) => ({
          id: index,  // Now the 'id' will be the index of the __choice
          text: this.translationData[__choice]
      }))
      return _choices
    },
    storeQuestionChanges(){
      //find in this.modalData.blueprint the one that has Name === this.currentBlueprint.Name
      let index =  this.course.lessons.findIndex(question => question.Name == this.currentRowData.fullCorrectAnswer)
      this.currentRowData.question.answer = this.storedValues.correctAnswerIndex?.id
      //update data in currentBlueprint
      this.currentRowData.question.prop = this.storedValues.currentPropData
      //replace the blueprint with the new one
      this.course.lessons[index] = this.currentRowData.question
      //close
      this.editCurrentQuestionModal = false
      this.activityToast('Stored')
    },
    
    EditCurrentAnswerData(rowData, course){
      this.currentRowData = rowData;
      // Check if lesson.prop is an array for each lesson
      if (!Array.isArray(this.currentRowData.question.prop)) {
            this.currentRowData.question.prop = [];
      }
      //find the correct answer in this.currentRowData.question.Answers 
      let correctAnswer = this.currentRowData.question.choices[this.currentRowData.question.answer]
      this.storedValues.currentPropData = this.currentRowData.question.prop;

      this.storedValues.correctAnswerIndex = {
        id: this.currentRowData.question.answer,
        text: this.translationData[correctAnswer],
      };
      this.editCurrentQuestionModal = true;
    },
    FormatExamQuestionData(course, translationData) {
      let FormatedQuestionList = [];
      for (let lesson of course.lessons) {
          //find in lesson.choices the one that has the same index as lesson.answer
          FormatedQuestionList.push({
            name: `${lesson.question.replace('ExamQuestion', '')} - ${lesson.Name}` ,
            fullCorrectAnswer: `${lesson.Name}`,
            correctAnswer: translationData[lesson.choices[lesson.answer] + '_abbrv'] ,
            question: lesson,
          }) 
      }
     return FormatedQuestionList;
    },
    getTranslationData() {
      const i8ln = firebase.firestore().collection('i18n').doc('English');
      i8ln.get().then((snapshot) => {
        this.translationData = snapshot.data();
      });
    },
    async getCourse() {
      this.courses = [];
      const course =  firebase.firestore().collection('course').doc(this.$route.params.uid);
      course.get()
          .then(async (doc) => {
            let data = await doc.data();
            let model = false;
            try {
              model = await data.model.get().then((model) => {
                return model.data();
              });
            } catch (error) {
              this.$swal.fire({
                title: 'Error',
                text: 'Model not found',
                icon: 'error',
              });
              return false;
            }
            try {
              data.lessons.forEach((lesson)=>{
                if (lesson.prop === '') {
                  lesson.prop = [];
                } else {
                  lesson.prop = lesson.prop.split(',');
                }
              });
            } catch (error) {
              this.$swal.fire({
                title: 'Error',
                text: 'Error parsing props, make sure they exist in this course',
                icon: 'error',
              });
              return false;
            }
            this.course = {
              name: data.Name,
              active: data.Active,
              model: model,
              lessons: data.lessons,
            };
          });
    },
    activityToast(text) {
     this.$swal.fire({
        title: text, 
        icon: 'success',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showCloseButton: false,
        showCancelButton: false,
        cancelButtonColor: '#d33',
        confirmButtonText: 'View',
        confirmButtonColor: '#3085d6',
      })
    },
    saveEdit() {
      const courseData = firebase.firestore().collection('course').doc(this.$route.params.uid);
      this.course.lessons.forEach((lesson)=>{
        lesson.prop = lesson.prop.toString();
      });
      courseData.update({
        Name: this.course.name,
        Active: this.course.active,
        lessons: this.course.lessons,
      }).then(() => {
        this.getCourse();
        this.$swal.fire('Course has been updated');
      });
    },
  },
  watch: {

  },
  computed: {
    ExamQuestions(){
      return [
          {
            name: 'name',
            title: 'Question Name',
          },
          {
            name: '__slot:correctAnswer',
            title: 'Correct Answer',
          },{
            name: '__slot:actions',
            title: 'Actions',
          }
      ]
    },
  },

};
</script>
